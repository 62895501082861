<h2>Entrer une nouvelle donnée</h2>
<div class="mainContent">
    <form [formGroup]="dataForm" (ngSubmit)="onSubmit(dataForm.value)">
        <fieldset class="mainFormWithSuffix">
            
            <div class="prefix">
                <span class="prefixText" id="basic-addon1">Date</span>
            </div>
            <input id="datePicker" name="date" type="date" formControlName="date" class="userInput">
            <div class="nothing">
            </div>

            <div class="prefix">
                <span class="prefixText" id="basic-addon1">Compte courant</span>
            </div>
            <input id="compte_courant" name="compte_courant" type="number" formControlName="compte_courant" class="userInputPreSuffix">
            <div class="suffix">
                <span class="suffixText" id="basic-addon1">€</span>
            </div>

            <div class="prefix">
                <span class="prefixText" id="basic-addon1">Compte courant CZ</span>
            </div>
            <input id="compte_courant_cz" name="compte_courant_cz" type="number" formControlName="compte_courant_cz" class="userInputPreSuffix">
            <div class="suffix">
                <span class="suffixText" id="basic-addon1">CZK</span>
            </div>

            <div class="prefix">
                <span class="prefixText" id="basic-addon1">LDD</span>
            </div>
            <input id="ldd" name="ldd" type="number" formControlName="ldd" class="userInputPreSuffix">
            <div class="suffix">
                <span class="suffixText" id="basic-addon1">€</span>
            </div>
<!-- 
            <div class="prefix">
                <span class="prefixText" id="basic-addon1">LDD Lucie</span>
            </div>
            <input id="ldd_lucie" name="ldd_lucie" type="number" formControlName="ldd_lucie" class="userInputPreSuffix">
            <div class="suffix">
                <span class="suffixText" id="basic-addon1">€</span>
            </div> -->

            <div class="prefix">
                <span class="prefixText" id="basic-addon1">CSOB Savings</span>
            </div>
            <input id="csob_savings" name="csob_savings" type="number" formControlName="csob_savings" class="userInputPreSuffix">
            <div class="suffix">
                <span class="suffixText" id="basic-addon1">CZK</span>
            </div>

            <div class="prefix">
                <span class="prefixText" id="basic-addon1">CSOB Bohatstvi</span>
            </div>
            <input id="csob_bohatstvi" name="csob_bohatstvi" type="number" formControlName="csob_bohatstvi" class="userInputPreSuffix">
            <div class="suffix">
                <span class="suffixText" id="basic-addon1">CZK</span>
            </div>

            <div class="prefix">
                <span class="prefixText" id="basic-addon1">CSOB Velmi Opatrny</span>
            </div>
            <input id="csob_velmi_opatrny" name="csob_velmi_opatrny" type="number" formControlName="csob_velmi_opatrny" class="userInputPreSuffix">
            <div class="suffix">
                <span class="suffixText" id="basic-addon1">CZK</span>
            </div>

            <div class="prefix">
                <span class="prefixText" id="basic-addon1">CSOB Akciovy</span>
            </div>
            <input id="csob_akciovy" name="csob_akciovy" type="number" formControlName="csob_akciovy" class="userInputPreSuffix">
            <div class="suffix">
                <span class="suffixText" id="basic-addon1">CZK</span>
            </div>
<!-- 
            <div class="prefix">
                <span class="prefixText" id="basic-addon1">PEL</span>
            </div>
            <input id="pel" name="pel" type="number" formControlName="pel" class="userInputPreSuffix">
            <div class="suffix">
                <span class="suffixText" id="basic-addon1">€</span>
            </div> -->

            <!-- <div class="prefix">
                <span class="prefixText" id="basic-addon1">PEL Lucie</span>
            </div>
            <input id="pel_lucie" name="pel_lucie" type="number" formControlName="pel_lucie" class="userInputPreSuffix">
            <div class="suffix">
                <span class="suffixText" id="basic-addon1">€</span>
            </div> -->

            <!-- <div class="prefix">
                <span class="prefixText" id="basic-addon1">Assurance Vie Crédit Agricole</span>
            </div>
            <input id="av_ca" name="av_ca" type="number" formControlName="av_ca" class="userInputPreSuffix">
            <div class="suffix">
                <span class="suffixText" id="basic-addon1">€</span>
            </div>

            <div class="prefix">
                <span class="prefixText" id="basic-addon1">Generali Actif General</span>
            </div>
            <input id="generali_actif_general" name="generali_actif_general" type="number" formControlName="generali_actif_general" class="userInputPreSuffix">
            <div class="suffix">
                <span class="suffixText" id="basic-addon1">€</span>
            </div>
            
            <div class="prefix">
                <span class="prefixText" id="basic-addon1">Generali SCI</span>
            </div>
            <input id="generali_sci" name="generali_sci" type="number" formControlName="generali_sci" class="userInputPreSuffix">
            <div class="suffix">
                <span class="suffixText" id="basic-addon1">€</span>
            </div>

            <div class="prefix">
                <span class="prefixText" id="basic-addon1">Generali OPCI</span>
            </div>
            <input id="generali_opci" name="generali_opci" type="number" formControlName="generali_opci" class="userInputPreSuffix">
            <div class="suffix">
                <span class="suffixText" id="basic-addon1">€</span>
            </div> -->
<!-- 
            <div class="prefix">
                <span class="prefixText" id="basic-addon1">Advize</span>
            </div>
            <input id="advize" name="advize" type="number" formControlName="advize" class="userInputPreSuffix">
            <div class="suffix">
                <span class="suffixText" id="basic-addon1">€</span>
            </div> -->

            <div class="prefix">
                <span class="prefixText" id="basic-addon1">Ceska Sporitelny</span>
            </div>
            <input id="ceska_sporitelny" name="ceska_sporitelny" type="number" formControlName="ceska_sporitelny" class="userInputPreSuffix">
            <div class="suffix">
                <span class="suffixText" id="basic-addon1">CZK</span>
            </div>

            <div class="prefix">
                <span class="prefixText" id="basic-addon1">Kooperativa</span>
            </div>
            <input id="kooperativa" name="kooperativa" type="number" formControlName="kooperativa" class="userInputPreSuffix">
            <div class="suffix">
                <span class="suffixText" id="basic-addon1">CZK</span>
            </div>

            <!-- <div class="prefix">
                <span class="prefixText" id="basic-addon1">Assurance vie Lucie</span>
            </div>
            <input id="av_lucie" name="av_lucie" type="number" formControlName="av_lucie" class="userInputPreSuffix">
            <div class="suffix">
                <span class="suffixText" id="basic-addon1">€</span>
            </div> -->

            <div class="prefix">
                <span class="prefixText" id="basic-addon1">October</span>
            </div>
            <input id="october" name="october" type="number" formControlName="october" class="userInputPreSuffix">
            <div class="suffix">
                <span class="suffixText" id="basic-addon1">€</span>
            </div>

            <!-- <div class="prefix">
                <span class="prefixText" id="basic-addon1">Actions Wavestone</span>
            </div>
            <input id="action_ws" name="action_ws" type="number" formControlName="action_ws" class="userInputPreSuffix">
            <div class="suffix">
                <span class="suffixText" id="basic-addon1">€</span>
            </div> -->

            <div class="prefix">
                <span class="prefixText" id="basic-addon1">Actions SAP</span>
            </div>
            <input id="action_sap" name="action_sap" type="number" formControlName="action_sap" class="userInputPreSuffix">
            <div class="suffix">
                <span class="suffixText" id="basic-addon1">€</span>
            </div>

            <div class="prefix">
                <span class="prefixText" id="basic-addon1">Actions Revolut</span>
            </div>
            <input id="action_revolut" name="action_revolut" type="number" formControlName="action_revolut" class="userInputPreSuffix">
            <div class="suffix">
                <span class="suffixText" id="basic-addon1">€</span>
            </div>
            
            <div class="prefix">
                <span class="prefixText" id="basic-addon1">Cryptocurrency</span>
            </div>
            <input id="crypto" name="crypto" type="number" formControlName="crypto" class="userInputPreSuffix">
            <div class="suffix">
                <span class="suffixText" id="basic-addon1">€</span>
            </div>

            <div class="prefix">
                <span class="prefixText" id="basic-addon1">Tickets restaurant</span>
            </div>
            <input id="tickets_resto" name="tickets_resto" type="number" formControlName="tickets_resto" class="userInputPreSuffix">
            <div class="suffix">
                <span class="suffixText" id="basic-addon1">CZK</span>
            </div>

            <!-- <div class="prefix">
                <span class="prefixText" id="basic-addon1">Appartement Otrokovice</span>
            </div>
            <input id="appart_otroko" name="appart_otroko" type="number" formControlName="appart_otroko" class="userInputPreSuffix">
            <div class="suffix">
                <span class="suffixText" id="basic-addon1">€</span>
            </div> -->

            <div class="prefix">
                <span class="prefixText" id="basic-addon1">CZ to EUR</span>
            </div>
            <input id="cz_to_eur" name="cz_to_eur" type="number" formControlName="cz_to_eur" class="userInputPreSuffix">
            <div class="suffix">
                <span class="suffixText" id="basic-addon1">CZK</span>
            </div>

        </fieldset>

        <div class="btnList">
            <button type="submit" class="btnPerso btnSecondary flexitem">Enregistrer</button>
            <h5 *ngIf="reussi" class = "flexitem">C'est fait !</h5>
            <button type='reset' routerLink="/money" class="btnPerso btnGreyLight flexitem">Annuler</button>
        </div>
    </form>
</div>