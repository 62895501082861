import { Component,  NgZone, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';

@Component({
  selector: 'app-create-data',
  templateUrl: './create-data.component.html',
  styleUrls: ['./create-data.component.scss']
})
export class CreateDataComponent implements OnInit {
  public dataForm
  public reussi = false
  
  constructor(private formBuilder: FormBuilder, private firestore: AngularFirestore, private ngZone:NgZone, private router : Router) {
    this.dataForm = this.formBuilder.group({
      date: new Date().toISOString().substring(0, 10),
      appart_otroko : 0,
      compte_courant: 0,
      compte_courant_cz: 0,
      ldd: 2899,
      ldd_lucie: 0,
      pel : 0,
      pel_lucie : 0,
      av_ca : 0,
      generali_actif_general : 0,
      generali_sci : 0,
      generali_opci : 0,
      av_lucie : 0,
      october : 0,
      action_ws : 0,
      action_sap: 0,
      advize : 0,
      csob_bohatstvi : 0,
      csob_velmi_opatrny : 0,
      csob_akciovy : 0,
      action_revolut: 0,
      csob_savings: 0,
      term_deposit : 0,
      kooperativa:13126,
      ceska_sporitelny:24418,
      crypto : 0,
      tickets_resto : 0,
      cz_to_eur:25
    });
  }

  ngOnInit(): void {
  }

  onSubmit(dataPoint) {
    let test = this.firestore.collection('epargne_history')
    test.add({
      date: new Date(dataPoint.date),
      compte_courant: dataPoint.compte_courant,
      compte_courant_cz: dataPoint.compte_courant_cz,
      csob_savings: dataPoint.csob_savings,
      ldd: dataPoint.ldd,
      ldd_lucie: dataPoint.ldd_lucie,
      pel : dataPoint.pel,
      pel_lucie : dataPoint.pel_lucie,
      av_ca : dataPoint.av_ca,
      generali_actif_general : dataPoint.generali_actif_general,
      generali_sci : dataPoint.generali_sci,
      generali_opci : dataPoint.generali_opci,
      av_lucie : dataPoint.av_lucie,
      october : dataPoint.october,
      action_ws : dataPoint.action_ws,
      csob_bohatstvi : dataPoint.csob_bohatstvi,
      csob_velmi_opatrny : dataPoint.csob_velmi_opatrny,
      csob_akciovy : dataPoint.csob_akciovy,
      term_deposit : dataPoint.term_deposit,
      kooperativa : dataPoint.kooperativa,
      ceska_sporitelny : dataPoint.ceska_sporitelny,
      action_sap : dataPoint.action_sap,
      action_revolut : dataPoint.action_revolut,
      advize : dataPoint.advize,
      crypto : dataPoint.crypto,
      tickets_resto : dataPoint.tickets_resto,
      appart_otroko : dataPoint.appart_otroko,
      cz_to_eur: dataPoint.cz_to_eur
    }).then(() => {
      this.reussi = true
      setTimeout( () => {
        this.ngZone.run(()=>this.router.navigate(['/money']))
      }, 1000 );
      
    })
  }

}
