import { Component,  NgZone, OnInit } from '@angular/core';
import { formatDate } from '@angular/common';
import { FormBuilder, FormControl } from '@angular/forms';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';


@Component({
  selector: 'app-new-datapoint-common',
  templateUrl: './new-datapoint-common.component.html',
  styleUrls: ['./new-datapoint-common.component.scss']
})
export class NewDatapointCommonComponent implements OnInit {
  public dataForm
  public reussi = false

  dateFormat = "yyyy-MM-dd";
  language = "en";
  
  constructor(private formBuilder: FormBuilder, private firestore: AngularFirestore, private ngZone:NgZone, private router : Router) {
    this.dataForm = this.formBuilder.group({
      date: new Date().toISOString().substring(0, 10),
      debt_kb : 0,
      debt_dad :0,
      shared_account: 0,
      cz_to_eur: 25,
    });
  }


  ngOnInit(): void {
    (<HTMLInputElement>document.getElementById('datePicker')).valueAsDate = new Date();
  }

  onSubmit(dataPoint) {
    let test = this.firestore.collection('epargne_history_common')
    test.add({
      date: new Date(dataPoint.date),
      debt_kb: dataPoint.debt_kb,
      debt_dad_eur: dataPoint.debt_dad,
      debt_dad_cz:dataPoint.debt_dad * dataPoint.cz_to_eur,
      shared_account: dataPoint.shared_account,
      cz_to_eur: dataPoint.cz_to_eur,
    }).then(() => {
      this.reussi = true
      setTimeout( () => {
        this.ngZone.run(()=>this.router.navigate(['/moneyShared']))
      }, 1000 );
      
    })
  }
}
