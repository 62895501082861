<h2>Enter a new data point</h2>
<div class="mainContent">
    <form [formGroup]="dataForm" (ngSubmit)="onSubmit(dataForm.value)">
        <fieldset class="mainFormWithSuffix">
            
            <div class="prefix">
                <span class="prefixText" id="basic-addon1">Date</span>
            </div>
            <input id="datePicker" name="date" type="date" formControlName="date" class="userInput">
            <div class="nothing">
            </div>

            <div class="prefix">
                <span class="prefixText" id="basic-addon1">Shared account</span>
            </div>
            <input id="shared_account" name="shared_account" type="number" formControlName="shared_account" class="userInputPreSuffix">
            <div class="suffix">
                <span class="suffixText" id="basic-addon1">CZK</span>
            </div>
      
            <div class="prefix">
                <span class="prefixText" id="basic-addon1">Debt KB</span>
            </div>
            <input id="debt_kb" name="debt_kb" type="number" formControlName="debt_kb" class="userInputPreSuffix">
            <div class="suffix">
                <span class="suffixText" id="basic-addon1">CZK</span>
            </div>
                        
            <div class="prefix">
                <span class="prefixText" id="basic-addon1">Debt dad</span>
            </div>
            <input id="debt_dad" name="debt_dad" type="number" formControlName="debt_dad" class="userInputPreSuffix">
            <div class="suffix">
                <span class="suffixText" id="basic-addon1">€</span>
            </div>

            <div class="prefix">
                <span class="prefixText" id="basic-addon1">CZ to EUR</span>
            </div>
            <input id="cz_to_eur" name="cz_to_eur" type="number" formControlName="cz_to_eur" class="userInputPreSuffix">
            <div class="suffix">
                <span class="suffixText" id="basic-addon1">CZK</span>
            </div>

        </fieldset>
        <div class="btnList">
            <button type="submit" class="btnPerso btnSecondary flexitem">Save</button>
            <h5 *ngIf="reussi" class = "flexitem">Done!</h5>
            <button type='reset' routerLink="/moneyDeni" class="btnPerso btnGreyLight flexitem">Cancel</button>
        </div>
    </form>
</div>