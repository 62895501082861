import  { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';

@Injectable()
export class ChartService{

    constructor(private firestore: AngularFirestore) {
      }

    getHistoryData(dataset : string){
        return this.firestore.collection(dataset, ref => ref.orderBy('date')).valueChanges()
    }

    getDailyTicker(){
      return this.firestore.collection("XRP_ticker").valueChanges()
    }

    getTransactionHistory(){
      return this.firestore.doc("general_data/transactions_history").valueChanges()
    }

    getOpenOrders(){
      return this.firestore.doc("general_data/open_orders").valueChanges()
    }

    getBalance(){
      return this.firestore.doc("general_data/balance").valueChanges()
    }

    getThresholds(){
      return this.firestore.doc("XRP_thresholds/current_threshold").valueChanges()
    }

    getEpargneHistory(){
      return this.firestore.collection("epargne_history", ref => ref.orderBy('date')).valueChanges()
    }

    getEpargneHistoryDeni(){
      return this.firestore.collection("epargne_history_deni", ref => ref.orderBy('date')).valueChanges()
    }

    getEpargneHistoryCommom(){
      return this.firestore.collection("epargne_history_common", ref => ref.orderBy('date')).valueChanges()
    }
}